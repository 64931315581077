export interface TestimonialCard {
    heading: string;
    content: string;
    name: string;
    profession: string;
    icon: string;
    star: number;
}
export const testimonialCard: TestimonialCard[] = [
    {
        heading: "I was able to compare policies",
        content: "Seamless experience! I was able to compare policies and purchase car insurance in minutes. INSURE24 made everything so easy!",
        name: "Rohit Jain",
        profession: "Healthcare Professional",
        icon: "",
        star: 5,
    },
    {
        heading: "The process was super smooth",
        content: "Great customer service! They guided me through every step of my claim process. I’m so grateful for their support.",
        name: "Aseem Duggal",
        profession: "Healthcare Professional",
        icon: "",
        star: 5,
    },
    {
        heading: "I saved so much on my bike insurance",
        content: "I saved so much on my bike insurance! INSURE24’s platform showed me the best deals. Highly recommended!",
        name: "Sambhav Jain",
        profession: "Healthcare Professional",
        icon: "",
        star: 5,
    },
    {
        heading: "I found all my insurance needs in one place",
        content: "Hassle-free and quick! From buying insurance to getting my documents, the process was super smooth.",
        name: "Arjit Singhla",
        profession: "Healthcare Professional",
        icon: "",
        star: 5,
    },
    {
        heading: "Best platform to buy insurance",
        content: "Best platform for insurance! I found all my insurance needs in one place. The comparisons made choosing a policy easy.",
        name: "Rahul Gupta",
        profession: "Healthcare Professional",
        icon: "",
        star: 5,
    },
    {
        heading: "No paperwork, no headaches",
        content: "INSURE24 keeps it simple. I renewed my bike insurance online without any hassle. No more paperwork headaches.",
        name: "Aakash Kapoor",
        profession: "Healthcare Professional",
        icon: "",
        star: 5,
    }
]
export const testimonialCardTwo: TestimonialCard[] = [
    testimonialCard[3],testimonialCard[5],testimonialCard[4],testimonialCard[1],testimonialCard[2]
]