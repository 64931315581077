import Partner1 from "../../images/logo1.svg";
import Partner2 from "../../images/logo2.svg";
import Partner3 from "../../images/logo3.svg";
import Partner4 from "../../images/logo4.svg";
import Partner5 from "../../images/logo5.svg";
import Partner6 from "../../images/logo6.svg";
import Partner7 from "../../images/logo7.svg";
import Partner8 from "../../images/logo8.svg";
import Partner9 from "../../images/logo9.svg";
import { IAssociatedPartnersList } from "./types";

const ASSOCIATED_PARTNERS_LIST: IAssociatedPartnersList[] = [
    {
        link: "",
        image: { url: Partner1 }
    },
    {
        link: "",
        image: { url: Partner2 }
    },
    {
        link: "",
        image: { url: Partner3 }
    },
    {
        link: "",
        image: { url: Partner4 }
    },
    {
        link: "",
        image: { url: Partner5 }
    }, {
        link: "",
        image: { url: Partner6 }
    }, {
        link: "",
        image: { url: Partner7 }
    },
    {
        link: "",
        image: { url: Partner8 }
    },
    {
        link: "",
        image: { url: Partner9 }
    }, {
        link: "",
        image: { url: Partner1 }
    },
    {
        link: "",
        image: { url: Partner2 }
    },
    {
        link: "",
        image: { url: Partner3 }
    },
    {
        link: "",
        image: { url: Partner4 }
    },
    {
        link: "",
        image: { url: Partner5 }
    }, {
        link: "",
        image: { url: Partner6 }
    }, {
        link: "",
        image: { url: Partner7 }
    },
    {
        link: "",
        image: { url: Partner8 }
    },
    {
        link: "",
        image: { url: Partner9 }
    },
]

export {
    ASSOCIATED_PARTNERS_LIST
}