import React, { useState } from 'react';
import Image from 'next/image';
import plus from "../../../public/svg/plus.svg";
import minus from "../../../public/svg/minus.svg";
import styles from './style.module.scss';
import { faq, PAGE_DATA } from './constant';
import { CmsContent } from '@cars24/turbo-web/lib/cms-content';
import { Heading } from '@cars24/turbo-web/lib/heading';
import { Label } from '@cars24/turbo-web/lib/label';
import parentStyles from "../../styles/layouts.module.scss";

const Img_Plus = () => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Maths &#38; finance/Plus">
            <path id="Vector" d="M20.4774 11.9926C20.4774 12.1798 20.403 12.3593 20.2707 12.4917C20.1383 12.624 19.9588 12.6984 19.7716 12.6984H12.714V19.756C12.714 19.9432 12.6396 20.1227 12.5073 20.2551C12.3749 20.3874 12.1954 20.4618 12.0082 20.4618C11.821 20.4618 11.6415 20.3874 11.5092 20.2551C11.3768 20.1227 11.3025 19.9432 11.3025 19.756V12.6984H4.24483C4.05765 12.6984 3.87813 12.624 3.74578 12.4917C3.61342 12.3593 3.53906 12.1798 3.53906 11.9926C3.53906 11.8054 3.61342 11.6259 3.74578 11.4936C3.87813 11.3612 4.05765 11.2868 4.24483 11.2868H11.3025V4.2292C11.3025 4.04202 11.3768 3.86251 11.5092 3.73015C11.6415 3.59779 11.821 3.52344 12.0082 3.52344C12.1954 3.52344 12.3749 3.59779 12.5073 3.73015C12.6396 3.86251 12.714 4.04202 12.714 4.2292V11.2868H19.7716C19.9588 11.2868 20.1383 11.3612 20.2707 11.4936C20.403 11.6259 20.4774 11.8054 20.4774 11.9926Z" fill="#0462F0" />
        </g>
    </svg>
}
const FaqBlock = () => {
    const [expanded, setExpanded] = useState<number | null>(null);

    const toggleExpand = (index: number) => {
        setExpanded(expanded === index ? null : index);
    };

    return (
        <section className={`${styles.faqContainer} ${parentStyles.mViewLayout} ${parentStyles.appContentBlock}`}>
            <div className={`${styles.faqWrapper} ${parentStyles.appPageBlock}`}>

                <div className={styles.content}>
                    <Heading level={1} bold color="var(--grey-800)">{PAGE_DATA.title}</Heading>
                </div>
                <div className={styles.faqSection}>
                    <div>
                        {faq.map((item, index) => (
                            <div
                                key={index}
                                className={`${styles.faqWrapper} ${index !== faq.length - 1 ? styles.faqWrapperBorder : ''
                                    } ${styles.answerWithPadding}`}
                            >
                                <div
                                    className={styles.faqFlex}
                                    onClick={() => toggleExpand(index)}
                                >
                                    <div className={styles.question}>
                                        <Label level={2} color="var(--grey-700)">{item.question}</Label>
                                    </div>
                                    <div
                                        className={`${styles.icon} ${expanded === index ? styles.iconPadding : ''
                                            }`}
                                    >
                                        <Image src={expanded === index ? minus : plus} alt="toggle icon" />
                                    </div>
                                </div>
                                {expanded === index && (
                                    <div
                                        className={`${styles.answer}`}
                                    >
                                        <CmsContent content={item.answer} />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FaqBlock;
