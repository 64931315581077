"use client";
import Image from 'next/image';
import testimonialImg from "../../../public/images/homepage/testimonialImg.png";
import starRating from "../../../public/svg/starYellow.svg";
import { testimonialCard, testimonialCardTwo } from './constant';
import styles from './style.module.scss';
import blueStar from "../../../public/lottieFiles/blueStar.json"
import { useLottie } from "lottie-react";
import { Heading } from '@cars24/turbo-web/lib/heading';
import { BodyText } from '@cars24/turbo-web/lib/body-text';
import AppAnimatedImage from '../app-animated-image';
import GradientStar from "../../images/lotties/gradientStars.json";
import parentStyles from "../../styles/layouts.module.scss";
const Testimonial = () => {
  const options = { animationData: blueStar, loop: true };
  const { View: blueStartAnimation } = useLottie(options);

  return (
    <section className={`${styles.testimonialWrapperBlock} `}>
    <div className={`${parentStyles.appPageBlock} ${styles.appPageBlock} `}>
    <div className={` ${styles.testimonialWrapper}`}>
      {/* Gradient overlay for the disappearing effect */}
      <div className={styles.gradientOverlay}></div>
      <div className={styles.testimonialContent}>
          {/* <div>{"TESTIMONIALS"}</div> */}
          <div className={styles.blueStartTitle}>
          <div className={styles.titleWithStar}> <Heading color="var(--grey-800)" isBold level={1}>
            5 star
          </Heading>
            <AppAnimatedImage image={GradientStar} className={styles.bannerStarImg} width={'48px'} height="48px" />
            </div>
            </div>
          <Heading color="var(--grey-800)" isBold level={1}>
          reviews
          </Heading>

          {/* <div>reviews</div> */}
        </div>
      <div className={styles.testimonialFlex}>
        {/* <div className={styles.testimonialContent}>
          <div className={styles.blueStartTitle}> <Heading color="var(--grey-900)" isBold level={1}>
            5K+ 5 Star
          </Heading>
            <AppAnimatedImage image={GradientStar} className={styles.bannerStarImg} width={'34px'} height="34px" /></div>
          <Heading color="var(--grey-900)" isBold level={1}>
            reviews
          </Heading>
        </div> */}
        <div className={styles.cardColumn}>
          <div className={styles.cardWrapper}>
            {testimonialCard.map((item, index) => (
              <div key={index} className={styles.cardSection}>
                {/* <div className={styles.heading}> */}
                <Heading color="var(--grey-900)" isBold level={4}>
                  {item.heading}
                </Heading>
                <div className={styles.testimonialContent}>
                <BodyText color="var(--grey-900)" level={2}>
                  {item.content}
                </BodyText>
                </div>
                <div className={styles.cardBottom}>
                  <div>
                    {/* <div><Image src={testimonialImg} alt={item.heading} /></div> */}
                    <div>
                      <BodyText color="var(--grey-900)" level={1}>
                        {item.name}
                      </BodyText>
                      {/* <BodyText color="var(--grey-600)" level={2}>
                        {item.profession}
                      </BodyText> */}
                    </div>
                  </div>
                  <div className={styles.rating}>
                    <span><Image src={starRating} alt="rating" width={10}/></span>
                    <span>{item.star}</span>
                  </div>
                </div>
              </div>
            ))}
            {/* Duplicate the cards for seamless animation */}
            {testimonialCard.map((item, index) => (
              <div key={index + testimonialCard.length} className={styles.cardSection}>
                 <Heading color="var(--grey-900)" isBold level={4}>
                  {item.heading}
                </Heading>
                <div className={styles.testimonialContent}>
                <BodyText color="var(--grey-900)" level={2}>
                  {item.content}
                </BodyText>
                </div>
                <div className={styles.cardBottom}>
                  <div>
                    {/* <div><Image src={testimonialImg} alt={item.heading} /></div> */}
                    <div>
                      <BodyText color="var(--grey-900)" level={1}>
                        {item.name}
                      </BodyText>
                      {/* <BodyText color="var(--grey-600)" level={2}>
                        {item.profession}
                      </BodyText> */}
                    </div>
                  </div>
                  <div className={styles.rating}>
                    <span><Image src={starRating} alt="rating" /></span>
                    <span>{item.star}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.cardColumn}>
          <div className={styles.cardWrapper}>
            {testimonialCardTwo.map((item, index) => (
              <div key={index} className={styles.cardSection}>
                 <Heading color="var(--grey-900)" isBold level={4}>
                  {item.heading}
                </Heading>
                <div className={styles.testimonialContent}>
                <BodyText color="var(--grey-900)" level={2}>
                  {item.content}
                </BodyText>
                </div>
                <div className={styles.cardBottom}>
                  <div>
                    {/* <div><Image src={testimonialImg} alt={item.heading} /></div> */}
                    <div>
                      <BodyText color="var(--grey-900)" level={1}>
                        {item.name}
                      </BodyText>
                      {/* <BodyText color="var(--grey-600)" level={2}>
                        {item.profession}
                      </BodyText> */}
                    </div>
                  </div>
                  <div className={styles.rating}>
                    <span><Image src={starRating} alt="rating" /></span>
                    <span>{item.star}</span>
                  </div>
                </div>
              </div>
            ))}
            {/* Duplicate the cards for seamless animation */}
            {testimonialCardTwo.map((item, index) => (
              <div key={index + testimonialCard.length} className={styles.cardSection}>
                 <Heading color="var(--grey-900)" isBold level={4}>
                  {item.heading}
                </Heading>
                <div className={styles.testimonialContent}>
                <BodyText color="var(--grey-900)" level={2}>
                  {item.content}
                </BodyText>
                </div>
                <div className={styles.cardBottom}>
                  <div>
                    {/* <div><Image src={testimonialImg} alt={item.heading} /></div> */}
                    <div>
                      <BodyText color="var(--grey-900)" level={1}>
                        {item.name}
                      </BodyText>
                      {/* <BodyText color="var(--grey-600)" level={2}>
                        {item.profession}
                      </BodyText> */}
                    </div>
                  </div>
                  <div className={styles.rating}>
                    <span><Image src={starRating} alt="rating" /></span>
                    <span>{item.star}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      </div>
    </div>
    </section>
  );
};

export default Testimonial;