"use client";
import { HomePage } from "@/containers/homepage";
import "./globals.css";
import { useEffect } from "react";
import { initializeAnalytics } from "@/services/google-analytics";

export default function Home() {
  useEffect(() => {
    initializeAnalytics("GTM-TXDJT4J5");
  }, []);
  return (
    <div>
      <HomePage />
    </div>
  );
}
