"use client";
import Image from "next/image";
import card4svg from "../../../public/svg/card4svg.svg";
import cars24Logo from "../../../public/images/homepage/cars24Logo.svg";
import styles from "./style.module.scss";
import { Navbar } from "@/components/navbar";
import Footer from "@/components/footer";
import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import PocketInsurance from "@/components/pocket-insurance";
import WhyInsurance from "@/components/whyInsure";
import ScrollText from "@/components/scrollText";
import { MobileNavbar } from "@/components/mobileNavbar";
import Testimonial from "@/components/testimonial";
import whiteStar from "../../images/lotties/whiteStar.json";
import { Antonio } from "next/font/google";
import {
  BANNER_VIDEOS,
  BANNER_VIDEOS_STATIC_QUOTE,
  HOME_PAGE_DATA,
} from "./constants";
import AppAnimatedImage from "@/components/app-animated-image";
import ParentStyles from "../../styles/layouts.module.scss";
import BannerImage from "../../images/lotties/home-banner.json";
import BannerLogo from "../../images/white-logo.svg";
import { Button } from "@cars24/turbo-web/lib/button";
import { Heading } from "@cars24/turbo-web/lib/heading";
import { Label } from "@cars24/turbo-web/lib/label";
import BannerVideoCard from "@/components/bannerVideoCard";
import OurPartners from "@/components/our-partners";
import MotorInsurance from "@/components/motor-insurance";
import FaqBlock from "@/components/faq-block";
import StayConnected from "@/components/stay-connected";
import BuyInsuranceCard from "@/components/buy-insurance-card";
import AppPopup from "@/components/app-popup";
import AppVideoPlayer from "@/components/app-video-player";
import { sendAnalyticsData } from "@/services/google-analytics";
import { AnalyticsEvents } from "@/constants/appAnalyticsConstants";
// import { Button } from '@cars24/turbo-web/lib/button';
const Card = dynamic(() => import("@/components/gifCards"), {
  ssr: false, // Disable server-side rendering for lazy-loaded component
});

const antonio = Antonio({
  weight: ["400"], // Specify the weights you need
  subsets: ["latin"], // Define the subset (e.g., 'latin')
});

export const HomePage = () => {
  const [expandedIndex, setExpandedIndex] = useState<number>(1);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [playVideo, setPlayVideo] = useState("");
  // const options = { animationData: whiteStar, loop: true };
  // const { View: whiteStartAnimation } = useLottie(options);

  useEffect(() => {
    const checkScreenSize = () => {
      setTimeout(() => {
        setIsMobile(window.innerWidth < 1024);
      }, 2500);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const handleHover = (index: number) => {
    setExpandedIndex(index);
  };

  const handleMouseLeave = () => {
    setExpandedIndex(1); // Collapse all cards when mouse leaves
  };

  return (
    <div>
      <div className={styles.instantSection}>
        <div className={ParentStyles.mView}>
          <MobileNavbar />{" "}
        </div>
        <div className={ParentStyles.dView}>
          <Navbar />
        </div>
        <div className={styles.pageBannerContent}>
          <AppAnimatedImage
            image={BannerImage}
            className={styles.animatedBannerImg}
            height="100%"
            width="100%"
            loop={true}
          />
          <div className={styles.bannerData}>
            <div className={styles.logoSection}>
              <div className={styles.subLogo}>
                <Image
                  src={cars24Logo}
                  alt="Cars24 Logo"
                  height={isMobile ? 37 : 42}
                />
              </div>
              <div className={styles.logoText}>{HOME_PAGE_DATA.title}</div>
              <div className={styles.logo}>
                <Image
                  src={BannerLogo}
                  alt="Loans24 Logo"
                  // height={isMobile ? 56 : 91}
                />
                <div className={`${styles.quote} ${antonio.className}`}>
                  {HOME_PAGE_DATA.tag}
                  <div className={styles.whiteStar}>
                    <AppAnimatedImage
                      image={whiteStar}
                      className={styles.bannerStarImg}
                      width={"34px"}
                      height="34px"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className={styles.buttonContainer}>
                    <div
                        className={styles.instantButton}
                        onClick={() => {
                            window.scrollTo({
                                top: isMobile ? 712 : 922, // Scroll to 500px from the top
                                behavior: "smooth", // Smooth scroll
                            });
                        }}
                    >
                        {"Explore now"}
                    </div>
                </div> */}
            <div className={styles.exploreButton}>
              <Button
                label="Explore now"
                onClick={() => {
                  sendAnalyticsData(
                    AnalyticsEvents.homepage.explore_now_staticpage.name,
                    AnalyticsEvents.homepage.data
                  );
                  window.scrollTo({
                    top: isMobile ? 712 : 922, // Scroll to 500px from the top
                    behavior: "smooth", // Smooth scroll
                  });
                }}
                variant="secondary"
              />
            </div>
            <div
              className={`${
                isMobile ? styles.scrollContainer : styles.container
              } ${styles.bannerContainer}`}
            >
              {BANNER_VIDEOS.map((reels, index) => (
                <div key={"reels" + index}>
                  <BannerVideoCard
                    setPlayVideo={() => {
                      setPlayVideo(reels.videoUrl);
                    }}
                    thumbnail={reels.thumbnail}
                    videoUrl={reels.videoUrl}
                    placeholderUrl={reels.placeholderImg}
                    text={reels.text}
                    name={reels.name}
                    position={reels.position}
                    isExpanded={isMobile ? true : expandedIndex === index + 1}
                    onHover={() => handleHover(index + 1)}
                    onMouseLeave={handleMouseLeave}
                  />
                </div>
              ))}

              {/* <Card
                        gifUrl={"/hp-assets/card2.mp4"}
                        placeholderUrl={card2.src}
                        text="“We want to offer a complete bouquet of insurance products to our customers.”"
                        name="Vikram Singh Pathania"
                        position="Vice President - Consumer Finance"
                        isExpanded={isMobile ? true : expandedIndex === 2}
                        onHover={() => handleHover(2)}
                        onMouseLeave={handleMouseLeave}
                    />
                    <Card
                        gifUrl={"/hp-assets/card3.mp4"}
                        placeholderUrl={card3.src}
                        text="“INSURE24 is a comprehensive platform offering a seamless, end-to-end insurance journey.”"
                        name="Ruchit Agarwal"
                        position="CFO - Chief Finance Officer, CARS24"
                        isExpanded={isMobile ? true : expandedIndex === 3}
                        onHover={() => handleHover(3)}
                        onMouseLeave={handleMouseLeave}
                    /> */}
              <div className={styles.staticCard}>
                <div className={styles.staticCardIcon}>
                  <Image src={card4svg} alt="icon" width={51} height={39} />
                </div>
                <Heading level={4} semibold color="var(--grey-800, #262626)">
                  {BANNER_VIDEOS_STATIC_QUOTE.title}
                </Heading>
                <div className={styles.name}>
                  <Label level={2} color="var(--grey-800, #262626)">
                    {BANNER_VIDEOS_STATIC_QUOTE.name}
                  </Label>
                </div>
                <Label level={2} color="var(--grey-800, #262626)">
                  {BANNER_VIDEOS_STATIC_QUOTE.position}
                </Label>
                {/* <div className={styles.staticContent}>
                            {
                                "We’re all set to become India's top insurance marketplace, making it easy for customers to compare, select, and buy insurance. Our focus is on simplifying the process so everyone can choose the right coverage for their needs"
                            }
                            <br /> {"coverage."}
                        </div>
                        <div className={styles.cardCofounder}>
                            {"Ruchit Agarwal"}
                        </div>
                        <div className={styles.cardCofounderPost}>
                            {"CFO - Chief Finance Officer, CARS24"}
                        </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <MotorInsurance />
      <PocketInsurance />
      <WhyInsurance />
      <OurPartners />
      <ScrollText />
      <Testimonial />
      <BuyInsuranceCard />
      <StayConnected />
      <FaqBlock />
      {/* {!isMobile && (
                <div className={styles.buyInsureWrapper}>
                    <div className={styles.buyInsure}>
                        <div>
                            <div className={styles.buyContent}>
                                Buy Insurance <br /> in just 5 mins!
                            </div>
                            <Button
                                text="Insure now"
                                href="https://i24stage.in/car-insurance-products/"
                            />
                        </div>
                        <div>
                            <Image src={buyInsureSvg} alt="buyInsure" />
                        </div>
                    </div>
                </div>
            )}
            <div className={styles.faqWrapper}>
                <div className={styles.faqHeading}>
                    {"Need help? Find answers here"}
                </div>
                <Faq />
            </div> */}
      {/* {isMobile ? <MobileFooter /> : <Footer />} */}
      <Footer />

      {playVideo && (
        <AppPopup
          show={playVideo ? true : false}
          onClose={() => {
            setPlayVideo("");
          }}
          width="60vw"
          height="80vh"
        >
          <AppVideoPlayer
            url={playVideo || ""}
            controls={true}
            playing={true}
            muted={false}
            height="60vh"
            playInline={true}
          />
        </AppPopup>
      )}
    </div>
  );
};
