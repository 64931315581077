import styles from "./style.module.scss";
import { PAGE_DATA, whyInsurance } from "./constant";
import List from "../list";
import parentStyles from "../../styles/layouts.module.scss";
import { Heading } from '@cars24/turbo-web/lib/heading';
import AppAnimatedImage from "../app-animated-image";
import GradientStar from "../../images/lotties/gradientStars.json";
const WhyInsurance = () => {
    return (
        <section className={` ${styles.whySection} ${styles.stayConnectedContainer} ${parentStyles.mViewLayout} ${parentStyles.appContentBlock}`}>
            <div className={`${styles.listWrapper} ${parentStyles.appPageBlock}`}>
                <div className={styles.heading}>
                    <div className={styles.titleWithStar}>
                        <Heading level={1} bold color="var(--grey-800)">{PAGE_DATA.title}</Heading>
                        <AppAnimatedImage image={GradientStar} className={styles.bannerStarImg} width={'48px'} height="48px" />
                    </div>
                </div>
                <div className={styles.whyFlex}>
                    {whyInsurance.map((item, index) => <div key={index + 'insure'} className={styles.item}>
                        <List item={item} />
                    </div>)}
                </div>
            </div>
        </section>
    );
};

export default WhyInsurance;
