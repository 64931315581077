"use client";
import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";
import play from "../../../public/svg/play.svg";
import styles from "./style.module.scss";
import { Heading } from '@cars24/turbo-web/lib/heading';
import { Label } from '@cars24/turbo-web/lib/label';
import { url } from "inspector";
import AppPopup from "../app-popup";
import AppVideoPlayer from "../app-video-player";
interface CardProps {
    // gifUrl: string;
    placeholderUrl: any;
    text: string;
    name: string;
    position: string;
    isExpanded: boolean;
    onHover: () => void;
    onMouseLeave: () => void;
    thumbnail:any;
    videoUrl:any;
    setPlayVideo:(e)=>void
}



const BannerVideoCard: React.FC<CardProps> = ({
    // gifUrl,
    placeholderUrl,
    text,
    name,
    position,
    isExpanded,
    onHover,
    onMouseLeave,
    thumbnail,
    videoUrl,
    setPlayVideo
}) => {
    const [currentImage, setCurrentImage] = useState<string>(placeholderUrl);
    const videoRef: any = useRef(false);

    const handleMouseEnter = () => {
        setCurrentImage(thumbnail);
        // videoRef.current.play();
      
        onHover();
    };

    const handleMouseLeave = () => {
        setCurrentImage(placeholderUrl);
        // videoRef.current.pause();
        onMouseLeave();
    };

    useEffect(() => {
        //isExpanded ? "" : videoRef.current.pause();
    }, [isExpanded]);
    // const [playVideo, setPlayVideo] = useState(false);
    return (
        <div
            className={`${styles.card} ${isExpanded ? styles.expanded : ""}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {/* <video
                muted
                ref={videoRef}
                style={{
                    minWidth: "360px",
                    position: "relative",
                    top: "-100px",
                }}
            >
                <source src={gifUrl} type="video/mp4" />
            </video> */}
            <Image src={isExpanded ? thumbnail : placeholderUrl} alt="banner-img"  style={{
                    minWidth: "360px",
                    position: "relative",
                    top: "-100px",
                }}/>
                
            {isExpanded && (
                <>
                    <div
                        className={styles.playButton}
                        onClick={() => {
                            setPlayVideo(true);
                        }}
                    >
                        <Image
                            src={play}
                            alt="Play icon"
                            className={styles.playIcon}
                        />
                    </div>
                    <div className={styles.cardContent}>
                        <Heading level={4} semibold color="var(--base-white)">{text}</Heading>
                        <div className={styles.roleBlock}>
                        <Label level={2} semibold color="var(--base-white)">{name}</Label>
                        <Label level={3}  color="var(--base-white)">{position}</Label>
                        </div>
                    </div>
                    {/* <div className={styles.cardShadow}>

                    </div> */}
                </>
            )}

{/* {playVideo && <AppPopup show={playVideo} onClose={() => { setPlayVideo(false) }} width="60vw"  height="80vh">
        <AppVideoPlayer url={videoUrl || ""}
          controls={true}
          playing={true}
          muted={false}
          height="60vh"
          playInline={true}
        />
      </AppPopup>} */}
        </div>
    );
};

export default BannerVideoCard;
