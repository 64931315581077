export const IMG_ARROW_RIGHT = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" fill="white" />
      <circle cx="24" cy="24" r="23.5" stroke="#0463F0" />
      <path
        d="M29.7503 24.6063L21.1789 33.1777C21.0993 33.2573 21.0047 33.3205 20.9007 33.3636C20.7966 33.4067 20.6851 33.4289 20.5725 33.4289C20.4598 33.4289 20.3483 33.4067 20.2443 33.3636C20.1402 33.3205 20.0457 33.2573 19.966 33.1777C19.8864 33.0981 19.8232 33.0035 19.7801 32.8995C19.737 32.7954 19.7148 32.6839 19.7148 32.5713C19.7148 32.4587 19.737 32.3471 19.7801 32.2431C19.8232 32.139 19.8864 32.0445 19.966 31.9648L27.9321 23.9998L19.966 16.0348C19.8052 15.874 19.7148 15.6559 19.7148 15.4284C19.7148 15.201 19.8052 14.9828 19.966 14.822C20.1269 14.6612 20.345 14.5708 20.5725 14.5708C20.7999 14.5708 21.0181 14.6612 21.1789 14.822L29.7503 23.3934C29.83 23.473 29.8932 23.5676 29.9364 23.6716C29.9795 23.7757 30.0017 23.8872 30.0017 23.9998C30.0017 24.1125 29.9795 24.224 29.9364 24.3281C29.8932 24.4321 29.83 24.5267 29.7503 24.6063Z"
        fill="#0463F0"
      />
    </svg>
  );
};

export const IMG_ARROW_LEFT = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="48"
        height="48"
        transform="matrix(-1 0 0 1 48 0)"
        fill="white"
      />
      <circle
        cx="24"
        cy="24"
        r="23.5"
        transform="matrix(-1 0 0 1 48 0)"
        stroke="#0463F0"
      />
      <path
        d="M18.2497 24.6063L26.8211 33.1777C26.9007 33.2573 26.9953 33.3205 27.0993 33.3636C27.2034 33.4067 27.3149 33.4289 27.4275 33.4289C27.5402 33.4289 27.6517 33.4067 27.7557 33.3636C27.8598 33.3205 27.9543 33.2573 28.034 33.1777C28.1136 33.0981 28.1768 33.0035 28.2199 32.8995C28.263 32.7954 28.2852 32.6839 28.2852 32.5713C28.2852 32.4587 28.263 32.3471 28.2199 32.2431C28.1768 32.139 28.1136 32.0445 28.034 31.9648L20.0679 23.9998L28.034 16.0348C28.1948 15.874 28.2852 15.6559 28.2852 15.4284C28.2852 15.201 28.1948 14.9828 28.034 14.822C27.8731 14.6612 27.655 14.5708 27.4275 14.5708C27.2001 14.5708 26.9819 14.6612 26.8211 14.822L18.2497 23.3934C18.17 23.473 18.1068 23.5676 18.0636 23.6716C18.0205 23.7757 17.9983 23.8872 17.9983 23.9998C17.9983 24.1125 18.0205 24.224 18.0636 24.3281C18.1068 24.4321 18.17 24.5267 18.2497 24.6063Z"
        fill="#0463F0"
      />
    </svg>
  );
};
