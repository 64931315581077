import { isMobileView } from "@/utils/responsive";
import { IPocketInsuranceCardDetails } from "./types";
import { EXTERNAL_URLS } from "@/constants/urls.constants";
import { AnalyticsEvents } from "@/constants/appAnalyticsConstants";
export const POCKET_INSURANCE_CARD_DETAILS: IPocketInsuranceCardDetails[] = [
  {
    type: "UpiProtect",
    tag: "ASSISTANCE",
    title: "UPI Protect",
    price: "₹1.09",
    description:
      "Shield your money with UPI Protect, keeping your digital payments safe and secure.",
    features: ["One call to block all cards.", "Fraud coverage up to ₹100,000"],
    cta: "Buy Now",
    url: EXTERNAL_URLS.UPI_PROTECT,
    eventName: AnalyticsEvents.homepage.upi_buynow_staticpage.name,
  },
  {
    type: "CardProtect",
    tag: "ASSISTANCE",
    title: "Card Protect",
    price: "₹1.36",
    description:
      "Protect your debit and credit cards, ensuring hassle-free transactions and security against fraud.",
    features: [
      "Fraud coverage up to ₹100,000",
      "Free annual Sony LIV subscription.",
    ],
    cta: "Buy Now",
    url: EXTERNAL_URLS.CARD_PROTECT,
    eventName: AnalyticsEvents.homepage.card_buynow_staticpage.name,
  },
  {
    type: "HealthGuard",
    tag: "WELLNESS",
    title: "HealthGuard Plus",
    price: "₹5.47",
    description:
      "Holistic healthcare support for critical illness, daily hospital expenses, and pharmacy discounts.",
    features: [
      "Personal accident (PA) cover up to ₹50,000.",
      "Critical illness cover up to 9 diseases.",
    ],
    cta: "Buy Now",
    url: EXTERNAL_URLS.HEALTH_GUARD_PLUS,
    eventName: "",
  },
  {
    type: "MobileScreenProtect",
    tag: "ASSISTANCE",
    title: "Mobile Screen Secure",
    price: "₹7",
    description: "Keep your phone’s display protected from cracks and damages.",
    features: [
      "Free pickup and drop for repairs.",
      "Guaranteed claim approval.",
    ],
    cta: "Buy Now",
    url: EXTERNAL_URLS.MOBILE_SCREEN_SECURE, //test data
    eventName: AnalyticsEvents.homepage.mobile_buynow_staticpage.name,
  },
  {
    type: "CarAssistance",
    tag: "DRIVE SECURE",
    title: "Car Assistance",
    price: "₹0.19",
    description:
      "Benefit from comprehensive puncture cover and car key replacement to keep your journey smooth and worry-free.",
    features: ["Pan India puncture cover.", "Key assistance cover."],
    cta: "Buy Now",
    url: EXTERNAL_URLS.CAR_ASSISTANCE,
    eventName: AnalyticsEvents.homepage.car_assitance_buy_now_staticpage.name,
  },
];

const cardsToShow = () => {
  if (typeof window != "undefined") {
    const cardWidth = 380;
    return window.innerWidth > 1020
      ? window.innerWidth / cardWidth
      : window.innerWidth / cardWidth;
  } else return 2.6;
};

export const SLIDER_SETTINGS = {
  // dots: true,
  // infinite: true,
  // slidesToShow:window.innerWidth > 1020 ? (window.innerWidth / 380): (window.innerWidth / 380),// cardsToShow(),
  // slidesToScroll: 1,
  // initialSlide: 0,
  // loop: true,
  // centerMode: false,
  // arrows: true,
  // variableWidth: true,

  dots: false,
  infinite: isMobileView() ? false : true,
  speed: 500,
  slidesToShow: isMobileView()
    ? 1
    : typeof window !== "undefined"
      ? window.innerWidth < 1100
        ? Math.min(1100, window.innerWidth - 32) / 300
        : Math.min(1300, window.innerWidth - 300) / 550
      : 4,
  slidesToScroll: 1,
  initialSlide: 0,
  loop: isMobileView() ? false : false,
  centerMode: isMobileView() ? true : false,
  arrows: false,
  variableWidth: isMobileView() ? true : true,
};
