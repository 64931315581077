import { useEffect, useRef, useState } from "react";
import imageGajendra from "../../../public/images/homepage/gajendra.png";
import styles from "./style.module.scss";
import Image from "next/image";
import parentStyles from "../../styles/layouts.module.scss";
const ScrollText = () => {
    const textRef = useRef<HTMLDivElement | null>(null);
    const [activeWordIndex, setActiveWordIndex] = useState(-1);

    const text =
        "At INSURE24, we're committed to providing comprehensive, customised, and reliable insurance solutions that offer peace of mind and financial security";

    useEffect(() => {
        const handleScroll = () => {
            if (textRef.current) {
                const rect = textRef.current.getBoundingClientRect();
                const windowHeight = window.innerHeight - 20;
                const textTop = rect.top;

                // Calculate the index of the word based on the scroll position
                const visiblePortion = windowHeight - textTop;
                const words = text.split(" ");
                const index = Math.min(
                    Math.floor(visiblePortion / 30),
                    words.length - 1
                );
                setActiveWordIndex(index);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [text]);

    return (
        <section
            className={`${styles.scrollTextWrapper} ${styles.grideWrapper} ${parentStyles.mViewLayout} ${parentStyles.appContentBlock}`}
        >
            <div className={styles.wrap}>
                <div className={styles.scrollText} ref={textRef}>
                    {text.split(" ").map((word, index) => (
                        <span
                            key={index}
                            className={
                                index <= activeWordIndex ? styles.active : ""
                            }
                        >
                            {word + " "}
                        </span>
                    ))}
                </div>
                <div className={styles.listFlex}>
                    <div>
                        <Image
                            src={imageGajendra}
                            alt=""
                            width={56}
                            height={56}
                            className={styles.image}
                        />
                    </div>
                    <div>
                        <div className={styles.listHeading}>
                            {"Gajendra Jangid"}
                        </div>
                        <div className={styles.listSubHeading}>
                            {"Chief Marketing Officer, CARS24"}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ScrollText;
