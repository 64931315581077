"use client";
import styles from "./styles.module.scss";
import { IPopUp } from "./types";
import CloseIcon from "../../images/close-icon-white.svg";
import Image from "next/image";

// import { CheckCircleIcon } from '@cars24/turbo-web/lib/icons/check-circle-icon';
const AppPopup = ({ title = '', show = false, onClose = (val) => { }, children, width = 'fit-content',
height = 'auto' }: IPopUp) => {
  const closeHandler = (e) => {
    onClose(false);
  };
  return (
    <div
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0"
      }}
      className={styles.overlay}
    >
      <span className={styles.close} onClick={(e) => closeHandler(e)}>
        <Image alt="close-menu"
          src={CloseIcon} width={20} height={20} />
        {/* <IconButton
              onClick={(e) => closeHandler(e)}
              rounded
              size="large"
              variant="background"
            >
            </IconButton> */}
      </span>
      <div className={`${styles.popup} `} style={{ width: width,height: height }}>
        {title && <h2>{title}</h2>}


        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default AppPopup;