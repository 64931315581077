import Image from 'next/image';
import styles from './style.module.scss';
import AppAnimatedImage from '../app-animated-image';
import { Heading } from '@cars24/turbo-web/lib/heading';
import { BodyText } from '@cars24/turbo-web/lib/body-text';
interface ListProps {
    item: {
        heading: string;
        icon: any;
        content: string;
    };
}

const List: React.FC<ListProps> = ({item}) => {


    return (
        <div className={styles.listWrapper}>
            <div>
                {/* <Image src={item.icon} alt={item.heading} /> */}
                <AppAnimatedImage image={item?.icon} className={styles.bannerStarImg}  />
            </div>
            <div className={styles.listContent}>
                    
                    <Heading level={3} semibold color="var(--grey-700)">{item.heading}</Heading>
                <div className={styles.content}>
                   <BodyText level={2}> {item.content}</BodyText>
                </div>
            </div>
        </div>
    );
};

export default List;
