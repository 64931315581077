'use client';
import styles from "./styles.module.scss";
import parentStyles from "../../styles/layouts.module.scss";
import { INSTAGRAM_REELS, PAGE_CONTENT } from "./constants";
import Image from "next/image";
import { Heading } from '@cars24/turbo-web/lib/heading';
import { Label } from '@cars24/turbo-web/lib/label';
import GradientStar from "../../images/lotties/gradientStars.json";
import AppAnimatedImage from "../app-animated-image";
import AppVideoPlayer from "../app-video-player";
import { useState } from "react";
import AppPopup from "../app-popup";
import { isMobileView } from "@/utils/responsive";
const StayConnected = () => {
  const [playVideo, setPlayVideo] = useState({ url: '' })

  return (
    <section className={`${styles.stayConnectedContainer} ${parentStyles.mViewLayout} ${parentStyles.appContentBlock}`}>
      <div className={styles.raysLightBg} />
      <div className={`${styles.appPageBlock} ${parentStyles.appPageBlock}`}>
        <div className={styles.titleBlock}>
          <div className={styles.titleWithStar}>
            <Heading color="var(--grey-800)" isBold level={1}>
              {PAGE_CONTENT.title}
            </Heading>
            <AppAnimatedImage image={GradientStar} className={styles.bannerStarImg} width={'48px'} height="48px" />
          </div>
        </div>

        <div className={styles.videos}>
          <div className={styles.leftBlock}>
            <AppVideoPlayer
              url={PAGE_CONTENT.video.url}
              backgroundImage={'url("' + PAGE_CONTENT.video.thumbnail + '")'}
              background="transparent"
              height={isMobileView() ? "208px" : "409px"}
              playInline={true}
              controls={true}
              playing={false}
              muted={false}
              light={false}
              onclose={() => { }}
            />
            <div className={styles.aboutVideo}>
              <Label level={2} fontWeight="medium" color="var(--grey-900)">{PAGE_CONTENT.video.title}</Label>
              <div className={styles.timeViews}>
                <Label level={3} color="var(--grey-500)">{PAGE_CONTENT.video.time}</Label>
                <div className={styles.divider}></div>
                <Label level={3} color="var(--grey-500)">{PAGE_CONTENT.video.views}</Label>
              </div>
            </div>
          </div>
          <div className={styles.rightBlock}>
            {INSTAGRAM_REELS.map((reel, index) => <div className={styles.instaReel} key={'insta-reel' + index}>
              <Image src={reel.thumbnail} alt="reels" className={styles.image} onClick={() => setPlayVideo(reel)} />
              <div className={styles.aboutVideo} onClick={() => setPlayVideo(reel)}>
                <Label level={3} fontWeight="semibold" color="var(--grey-600)" >{reel.title}</Label>
                <div className={styles.timeViews}>
                  <Label level={4} color="var(--grey-500)">{reel.time}</Label>
                  <div className={styles.divider}></div>
                  <Label level={4} color="var(--grey-500)">{reel.views}</Label>
                </div>
              </div>
            </div>)}
          </div>
        </div>
      </div>

      {playVideo?.url && <AppPopup show={playVideo?.url ? true : false} onClose={() => { setPlayVideo({ url: '' }) }} width="60vw" height="80vh">
        <AppVideoPlayer url={playVideo?.url || ""}
          controls={true}
          playing={true}
          muted={false}
          height="60vh"
          playInline={true}
        />
      </AppPopup>}
    </section>
  );
};
export default StayConnected;
