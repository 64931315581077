import Image from "next/image";
import styles from "./style.module.scss";
import parentStyles from "../../styles/layouts.module.scss";
import { Heading } from "@cars24/turbo-web/lib/heading";
import { Button } from "@cars24/turbo-web/lib/button";
import { BodyText } from "@cars24/turbo-web/lib/body-text";
import { MOTOR_INSURANCE_CARD_DETAILS } from "./constants";
import ChipImg from "../../../public/svg/check-circle.svg";
import GradientStar from "../../images/lotties/gradientStars.json";
import AppAnimatedImage from "../app-animated-image";
import { NavigateToLink } from "@/utils/navigation";
import { sendAnalyticsData } from "@/services/google-analytics";
import { AnalyticsEvents } from "@/constants/appAnalyticsConstants";

const MotorInsurance = () => {
  return (
    <section
      className={` ${styles.motorInsuranceContainer} ${parentStyles.mViewLayout} ${parentStyles.appContentBlock}`}
    >
      <div className={styles.raysLightBg}></div>
      <div className={`${parentStyles.appPageBlock}`}>
        <div className={styles.title}>
          <div className={styles.titleWithStar}>
            <Heading level={1} isBold color="var(--grey-800)">
              Motor insurance
            </Heading>
            <AppAnimatedImage
              image={GradientStar}
              className={styles.bannerStarImg}
              width={"48px"}
              height="48px"
            />
          </div>
        </div>
        <div className={styles.cardsContainer}>
          <div className={styles.cardBlock}>
            {MOTOR_INSURANCE_CARD_DETAILS.map((ele, index) => {
              return (
                <div
                  key={index + "MotorInsurance"}
                  className={`${styles.cardContent} ${
                    ele.cardType === "Bike" && styles.darkBlue
                  }`}
                >
                  <div className={styles.raysLightBgCard}></div>
                  <div className={styles.quoteBtn}>
                    <Button
                      label={ele.cta}
                      onClick={() => {
                        sendAnalyticsData(
                          ele.eventName,
                          AnalyticsEvents.homepage.data
                        );

                        NavigateToLink(ele.ctaLink);
                      }}
                      variant="tertiary"
                    />
                  </div>
                  <Image
                    src={ele.image}
                    alt="MotorImg"
                    className={styles.cardImg}
                    width={165}
                  />
                  <div className={styles.cardDescription}>
                    <Heading level={3} isBold={false} color="var(--base-white)">
                      {ele.title}
                    </Heading>
                    <BodyText level={2} color="var(--base-white)">
                      {ele.description}
                    </BodyText>
                  </div>
                  <div className={styles.highlightsBlock}>
                    {ele.highlights.map((item, index) => {
                      return (
                        <div
                          key={index + "CardHighlights"}
                          className={styles.highlight}
                        >
                          <div className={styles.leftBlock}>
                            <Image src={ChipImg} alt="ChipImg" />
                          </div>
                          <div className={styles.rightBlock}>
                            <BodyText level={2} color="var(--base-white)">
                              {item}
                            </BodyText>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MotorInsurance;
