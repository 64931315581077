"use client";
import Image from "next/image";
import styles from "./style.module.scss";
import parentStyles from "../../styles/layouts.module.scss";
import { Heading } from "@cars24/turbo-web/lib/heading";
import { Button } from "@cars24/turbo-web/lib/button";
import { Label } from "@cars24/turbo-web/lib/label";
import { Caption } from "@cars24/turbo-web/lib/caption";
import { BodyText } from "@cars24/turbo-web/lib/body-text";
import { IconButton } from "@cars24/turbo-web/lib/icon-button";
import { POCKET_INSURANCE_CARD_DETAILS, SLIDER_SETTINGS } from "./constants";
import CheckImg from "../../../public/svg/check-circle-blue.svg";
import AppAnimatedImage from "../app-animated-image";
import GradientStar from "../../images/lotties/gradientStars.json";
import { useRouter } from "next/navigation";
import Slider from "react-slick";
import { useRef } from "react";
import sliderStyles from "../../styles/carousel.module.scss";
import { IMG_ARROW_LEFT, IMG_ARROW_RIGHT } from "@/constants/images.constants";
import { NavigateToLink } from "@/utils/navigation";
import { sendAnalyticsData } from "@/services/google-analytics";
import { AnalyticsEvents } from "@/constants/appAnalyticsConstants";
const PocketInsurance = () => {
  let sliderRef: any = useRef(null);
  const router = useRouter();

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  var settings = {
    ...SLIDER_SETTINGS,
    appendDots: (dots, onClick) => (
      <div className={sliderStyles.slideIndicatorContainer}>
        <ul
          className={`${sliderStyles.slideIndicatorContainer} full-screen-indicator`}
          onClick={onClick}
        >
          {" "}
          {dots}{" "}
        </ul>
      </div>
    ),
    customPaging: (i) => <div className={sliderStyles.slideIndicator}></div>,
  };

  // var settings = {
  //     dots: true,
  //     infinite: true,
  //     speed: 500,
  //     slidesToShow: 3,
  //     slidesToScroll: 1,
  //     arrows: false,
  //     autoplay: true,
  //     autoplaySpeed: 5000,
  //     appendDots: (dots, onClick) => (
  //         <div

  //         >

  //         </div>
  //     ),

  // };
  return (
    <section
      className={` ${styles.pocketInsuranceWrapper} ${parentStyles.mViewLayout} ${parentStyles.appContentBlock}`}
    >
      <div className={`${styles.pocketInsuranceContainer}`}>
        <div className={`${styles.headerBlock}  ${parentStyles.appPageBlock}`}>
          <div className={styles.title}>
            <div className={styles.titleWithStar}>
              <Heading level={1} bold color="var(--grey-800)">
                {"Pocket insurance"}
              </Heading>
              <AppAnimatedImage
                image={GradientStar}
                className={styles.bannerStarImg}
                width={"48px"}
                height="48px"
              />
            </div>
          </div>
          <div className={sliderStyles.slickSliderNavigationBtns}>
            <span className={sliderStyles.prev}>
              <IconButton
                onClick={() => previous()}
                rounded
                variant="background"
                size="large"
              >
                <IMG_ARROW_LEFT />
              </IconButton>
            </span>
            <span className={sliderStyles.next}>
              <IconButton
                onClick={() => next()}
                rounded
                size="large"
                variant="background"
              >
                <IMG_ARROW_RIGHT />
              </IconButton>
            </span>
          </div>
        </div>
        <div className={styles.cardsContainer}>
          <div
            className={`${styles.cardBlock} ${sliderStyles.sliderTypeA} slider-type-a`}
          >
            <div
              className={`${styles.boxShadow} ${styles.boxShadowLeft}`}
            ></div>
            <Slider
              ref={(slider) => {
                sliderRef = slider;
              }}
              {...settings}
            >
              {POCKET_INSURANCE_CARD_DETAILS.map((ele, index) => (
                <div
                  className={styles.cardWrapper}
                  key={index + "PocketInsurance"}
                >
                  <div
                    className={`${styles.cardContent} 
                ${
                  ele.type === "DiwaliSurakshaPlan" && styles.diwaliSurakshaPlan
                }
                            ${ele.type === "UpiProtect" && styles.upiProtect}
                            ${ele.type === "CardProtect" && styles.cardProtect}
                            ${ele.type === "HealthGuard" && styles.healthGuard}
                            ${
                              ele.type === "MobileScreenProtect" &&
                              styles.mobileScreenProtect
                            }
                            ${
                              ele.type === "CarAssistance" &&
                              styles.carAssistance
                            }
                            `}
                  >
                    <div className={styles.topBorder}></div>
                    <div className={styles.topBlock}>
                      <div className={styles.tag}>
                        <Label
                          level={4}
                          color="var(--grey-900)"
                          fontWeight="semibold"
                        >
                          {ele.tag}
                        </Label>
                      </div>
                      <div className={styles.priceTag}>
                        <BodyText level={2} color="var(--base-white)">
                          from
                        </BodyText>
                        <Label
                          level={1}
                          color="var(--base-white)"
                          fontWeight="semibold"
                        >
                          {ele.price}
                        </Label>
                        <BodyText level={2} color="var(--base-white)">
                          /day
                        </BodyText>
                      </div>
                    </div>
                    <div className={styles.content}>
                      <div className={styles.cardDescription}>
                        <Heading
                          level={3}
                          color="var(--grey-900)"
                          fontWeight="semibold"
                        >
                          {ele.title}
                        </Heading>
                        <BodyText level={2} color="var(--grey-900)">
                          {ele.description}
                        </BodyText>
                      </div>
                      <div>
                        <div className={styles.featuresBlock}>
                          {ele.features.map((item, index) => {
                            return (
                              <div
                                key={index + "CardFeatures"}
                                className={styles.featuresList}
                              >
                                <div className={styles.leftBlock}>
                                  <Image src={CheckImg} alt="ChipImg" />
                                </div>
                                <div className={styles.rightBlock}>
                                  <Caption color="var(--grey-900)">
                                    {item}
                                  </Caption>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <Button
                          label={ele.cta}
                          onClick={() => {
                            ele.eventName &&
                              sendAnalyticsData(
                                ele.eventName,
                                AnalyticsEvents.homepage.data
                              );
                            NavigateToLink(ele.url);
                          }}
                          variant="tertiary"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* </Slider> */}
            </Slider>
            <div
              className={`${styles.boxShadow} ${styles.boxShadowRight}`}
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PocketInsurance;
