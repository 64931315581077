import InstaReel1 from "../../images/insta-reel1.jpg";
import InstaReel2 from "../../images/insta-reel2.jpg";
import InstaReel3 from "../../images/insta-reel3.jpg";
import InstaReel4 from "../../images/insta-reel4.jpg";

export const PAGE_CONTENT = {
    title: 'Stay connected!',
    video: {
        title: 'Future Proofed Podcast by INSURE24, Vikram Singh Pathania, VP, Consumer Finance',
        url: 'https://youtu.be/s2ORYUQ05FY?feature=shared',
        thumbnail: '../../images/stay-connected-video-thumbnail.svg',
        time: '1 week ago',
        views: '508 views',
    }
}

export const INSTAGRAM_REELS = [
    {
        title: 'Investment Vs Insurance : Catch exclusive insights from Vikram Singh Pathania, Vice President of CARS24',
        url: 'https://www.youtube.com/shorts/_fyQ31AoKzo',
        thumbnail: InstaReel1,
        time: '1 week ago',
        views: '508 views',
    },
    {
        title: 'With Pocket Insurance from INSURE24, you can protect your hard-earned money from scammers',
        url: 'https://www.youtube.com/shorts/xRfjloKUIWc',
        thumbnail: InstaReel2,
        time: '1 week ago',
        views: '508 views',
    },
    {
        title: 'CARS24 brings you INSURE24, your go-to for all things insurance!',
        url: 'https://www.youtube.com/shorts/9JBJxNIsEi0',
        thumbnail: InstaReel3,
        time: '1 week ago',
        views: '508 views',
    },
    {
        title: 'Ruchit Agarwal, Co-Founder of CARS24, shares the launch of INSURE24',
        url: 'https://www.youtube.com/shorts/rhHHb6CYW5A',
        thumbnail: InstaReel4,
        time: '1 week ago',
        views: '508 views',
    }
]