import whyChoose1 from "../../images/lotties/whyChoose1.json";
import whyChoose2 from "../../images/lotties/whyChoose2.json";
import whyChoose3 from "../../images/lotties/whyChoose3.json";
import whyChoose4 from "../../images/lotties/whyChoose4.json";

export interface WhuInsurance {
    heading: string;
    icon: any;
    content: string;
}
export const whyInsurance: WhuInsurance[] = [
    {
        heading: "Tailored solutions",
        icon: whyChoose4,
        content:
            "We offer need-based, customised insurance products with comprehensive coverage, designed to meet the unique needs of each customer.",
    },
    {
        heading: "Speed and efficiency",
        icon: whyChoose1,
        content:
            "Get covered fast and experience a hassle-free digital process. INSURE24 prioritizes your time by offering a smooth and swift experience.",
    },
    {
        heading: "Transparency and trust",
        icon: whyChoose2,
        content:
            "Clear and concise policy wordings, no hidden fees, no jargons, the price you see is the price you pay.",
    },
    {
        heading: "Expertise and reliability",
        icon: whyChoose3,
        content:
            "Deep understanding of the insurance industry with stability and peace of mind knowing you're in trusted hands.",
    },


];

export const PAGE_DATA = {
    title: "Why choose INSURE24?"
}