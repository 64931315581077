import ThumbnailImg1 from "../../images/ThumbnailImg1.gif"
import ThumbnailImg2 from "../../images/ThumbnailImg2.gif"
import ThumbnailImg3 from "../../images/ThumbnailImg3.gif"
import PlaceholderImg1 from "../../images/BannerImage1.png"
import PlaceholderImg2 from "../../images/BannerImage2.png"
import PlaceholderImg3 from "../../images/BannerImage3.png"
export const HOME_PAGE_DATA = {
    title: "PRESENTS",
    tag: 'YOU LOVE IT, WE INSURE IT'
}

export const BANNER_VIDEOS = [{
    thumbnail: ThumbnailImg1,
    placeholderImg: PlaceholderImg1,
    text: "“INSURE24 is a comprehensive platform offering a seamless, end-to-end insurance journey.”",
    name: "Ruchit Agarwal",
    position: "CFO - Chief Finance Officer, CARS24",
    videoUrl: 'https://youtube.com/shorts/rhHHb6CYW5A?si=vARw4OCcJUiWAFGI'
}, {
    thumbnail: ThumbnailImg2,
    placeholderImg: PlaceholderImg2,
    text: "“Our vision for INSURE24 is Ab Hoga India Insured”",
    name: "Vikram Singh Pathania",
    position: "Vice President - Consumer Finance",
    videoUrl: 'https://youtube.com/shorts/QF0sGF6Fd_k?si=NFh3kHppA_frNEAa'
}, {
    thumbnail: ThumbnailImg3,
    placeholderImg: PlaceholderImg3,
    text: "“We want to offer a complete bouquet of insurance products to our customers.”",
    name: "Vikram Singh Pathania",
    position: "Vice President - Consumer Finance",
    videoUrl: ' https://youtube.com/shorts/_fyQ31AoKzo?si=iRfcCg1GjPAV4m0e'
}]


export const BANNER_VIDEOS_STATIC_QUOTE = {
    title: "We’re all set to become India's top insurance marketplace, making it easy for customers to compare, select, and buy insurance. Our focus is on simplifying the process so everyone can choose the right coverage for their needs.",
    name: "Vikram Chopra",
    position: "Founder & CEO, CARS24"
}