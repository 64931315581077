"use client";
// https://www.npmjs.com/package/react-player
import ReactPlayer from 'react-player'
import styles from "./styles.module.scss";
import { IVideo } from "./types";
import AppPopup from '../app-popup';
import { useState } from 'react';
import PlayIcon from "../../images/play-icon.svg";
import Image from 'next/image';
const AppVideoPlayer = ({
  url = "",
  playing = false,
  controls = false,
  light = false,
  background = 'transparent',
  height = "auto",
  backgroundImage = "",
  playInline = true,
  onlyImage = false,
  onclose=()=>{}
}: IVideo) => {
  const [playVideo, setPlayVideo] = useState(false);
 
  return (
    <div className={`${styles.appVideoContainer} ${onlyImage && styles.onlyImage}
    ${backgroundImage && styles.hasBgImage} ${!playInline && styles.center}`} style={{ backgroundColor: background, backgroundImage: backgroundImage, height: height }}
      onClick={() => { if (!playInline) { setPlayVideo(!playVideo) } }}>
      {url && playInline && 
      
      <ReactPlayer url={url}
        playing={playing}
        controls={controls}
        light={light}
        height={height}
      />
      
      }

      {!playInline && <div className={`${styles.playImageWrapper} ${styles.center}`}>
        <Image alt="close-menu"
          src={PlayIcon} className={styles.playIcon} />
      </div>
      }
      {!playInline && <AppPopup show={playVideo} onClose={() => { onclose(); setPlayVideo(false) }} width="900px">
        <AppVideoPlayer
          url={url}
          controls={!onlyImage}
          playing={playing}
          muted={false}
          height="700px"
          playInline={true}
        />
      </AppPopup>}
    </div>
  );
};
export default AppVideoPlayer;
