export const PAGE_CONTENT = {
    title: 'Insure your car within minutes',
    video: {
        title: 'VSP explains the new venture by CARS24, an insurance product',
        url: 'https://youtu.be/6kQ4XQoS9Z4',
        thumbnail: '../../images/stay-connected-video-thumbnail.svg',
        time: '1 week ago',
        views: '508 views',
    }
}

