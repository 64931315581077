export interface FAQ {
    question: string;
    answer: string;
}
export const faq: FAQ[] = [
    {
        question: "What is insurance?",
        answer: "<div>Insurance is a contract between you (the policyholder), and the insurance company (the insurer) that protects you financially from any damage that your car, health, life, family, or belongings may incur. Insurance offers reimbursement for any financial damage caused with certain terms & conditions applied.</div>",
    },
    {
        question: "What are the various types of insurance?",
        answer: "<div>Insurance has 2 types - general insurance and life insurance. General insurance can be further categorised into  motor insurance (car and bike), health insurance, education insurance, home insurance, travel insurance, and more.</div>",
    },
    {
        question: "What is the purpose of insurance?",
        answer: "<div>The purpose of insurance is to reduce financial burden in the time of accidents and/or emergencies. The policyholder pays an amount to the insurance company which is called a premium in exchange for protection at the time of need.</div>",
    },
    {
        question: "Is having insurance mandatory?",
        answer: "<div>In motor insurance, having a third-party insurance cover is mandatory as per Indian law.</div>",
    },
    {
        question: "What is premium in insurance?",
        answer: "<div>The premium is the amount of money you pay regularly (monthly, annually) to maintain your insurance policy.</div>",
    },
    {
        question: "What is insurance risk?",
        answer: "<div>Insurance risk is the probability of an insurable event and the possible financial amount of the damages that might occur for which the policyholder will ask for a claim.</div>",
    },
    {
        question: "Who is a policyholder in insurance?",
        answer: "<div>The policyholder is the person who buys the insurance policy from the insurance company.</div>",
    },
    {
        question: " What is a claim in insurance?",
        answer: "<div>Claim is the reimbursement amount that the policyholder applies for at the time of financial loss that the person incurs due to the accident or the emergency.</div>",
    }, {
        question: "What is a loss in insurance?",
        answer: "<div>Insurance loss is the amount of money that the insurance company loses when they reimburse the claim amount. This could mean if a house is burned down due to fire, they will reimburse the entire amount to the policyholder.</div>",
    }, {
        question: "How can I renew my insurance policy?",
        answer: "<div>You can go to <a href='https://insure24.com/'>www.insure24.com</a>, select the insurance you have, enter your details, and renew your policy using the instructions provided in the dashboard.</div>",
    }, {
        question: "What is deductible in insurance?",
        answer: "<div>The deductible is a specific amount of money you are responsible for paying out of pocket before your insurance company starts covering the cost of a claim.  For example, if you have an INR 500 deductible on your car insurance and get into an INR 2,000 accident, you would be responsible for paying the first  INR 500 and your insurance company would cover the remaining INR 1,500.</div>",
    }, {
        question: "What kind of products does INSURE24 offer?",
        answer: "<div>We offer a variety of insurance products to suit your needs, including:<br/><ul><li>Car Insurance</li> <li>Two-wheeler insurance</li> <li>Health insurance</li> <li>Life insurance</li> <li>Pocket Insurance</li></ul></div>",
    },
];

export const PAGE_DATA = {
    title: "Need help? Find answers here"
}