"use client";
import Lottie from "lottie-react";
import { IAnimatedImage } from "./types";

const AppAnimatedImage = ({ loop = true, image = "", width = "100px", height = "auto", className = "" }: IAnimatedImage) => {
    return (
        <div style={{ "width": width, "height": height }} className={className}>
            <Lottie animationData={image} loop={loop} />
        </div>
    );
};

export default AppAnimatedImage;
