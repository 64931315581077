import styles from "./styles.module.scss";
import parentStyles from "../../styles/layouts.module.scss";
import { ASSOCIATED_PARTNERS_LIST } from "./constants";
import Image from "next/image";
import { Heading } from '@cars24/turbo-web/lib/heading';
import { IOurPartners } from "./types";

const OurPartners = ({ title = 'Our partners', partners = ASSOCIATED_PARTNERS_LIST }: IOurPartners) => {
  return (
    <section className={`${styles.ourPartnersContainer} ${parentStyles.mViewLayout} ${parentStyles.appContentBlock}`}>
      <div className={`${styles.appPageBlock} `}>
        <div className={styles.titleBlock}>
          <Heading color="var(--grey-800)" isBold level={1}>
            {title}
          </Heading>
        </div>

        <div className={`${styles.associatedPartnersBlock} theme-scrollbar`}>
          <div className={`${styles.boxShadowLeft} ${styles.boxShadow}`}></div>
          <div className={styles.animatedXScroll}>
            {partners.map((item, index) => (
              <div className={styles.logoBlock} key={'associated-partners' + index}>
                <Image
                  src={item?.image?.url}
                  alt={item?.image?.alternativeText || "Partners image"}
                  className={styles.partnerImage}
                  height={39}
                />
              </div>
            ))}
            {partners.map((item, index) => (
              <div className={styles.logoBlock} key={'associated-partners-clone' + index}>
                <Image
                  src={item?.image?.url}
                  alt={item?.image?.alternativeText || "Partners image"}
                  className={styles.partnerImage}
                  height={39}
                />
              </div>
            ))}
          </div>
          <div className={`${styles.boxShadowRight} ${styles.boxShadow}`}></div>

        </div>
      </div>
    </section>
  );
};
export default OurPartners;
